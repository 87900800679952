import Layout from '@components/layout';
interface IComponentProps {

}

const Component = (props: IComponentProps) => {
  return (
    <Layout padding header footer>
      <div>APP HOME</div>
    </Layout>
  )
}

export default Component;