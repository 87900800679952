import GeneralLanding from './general/landing';
import AppHome from './viz/appHome';
import AppComponent from './viz/appComponents';

export const generalPage = {
  GeneralLanding
}

export const vizPage = {
  AppHome,
  AppComponent
}